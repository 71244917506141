
import Styles from './app.module.css'
import { Helmet } from 'react-helmet'

import Calculator from './scalc.js'

import Logo from './Media/Logo1.png'

import IconPeople from './Media/Icon_People.png'
import IconMarketing from './Media/Icon_Marketing.png'
import IconTime from './Media/Icon_Time.png'
import IconCale from './Media/Icon_Calen.png'
import IconAna from './Media/Icon_Ana.png'

import IconTrans from './Media/translation.png'



import ImgGreet from './Media/Greet.png'
import ImgSve from './Media/saving.png'
import ImgErr from './Media/404.png'

import ImgLogoBerk from './Media/Ber.jpg'
import ImgLogoReg from './Media/Reg.jpg'

import VidGif from './Media/Gif.gif'
import MetGif from './Media/Meetgif1.gif'

import { useRef } from 'react'

import { BrowserRouter, Route, Router, Link, Routes, NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { calculateNewValue } from '@testing-library/user-event/dist/utils'

import ContactUs from './contact'
import { getByDisplayValue } from '@testing-library/react'
import { useState } from 'react'

function App() {

  const navigate = useNavigate();
  const calcRef = useRef()

  const [turkish, setturkish] = useState(true)
  
  function scrollTo() {
    navigate("/")
    setTimeout(() => {
      calcRef.current.scrollIntoView({behavior:"smooth", block: "start"});  
    }, 200);
    
  }

  const videoRed = useRef()

  const forceplayVid = () => {
    console.log("Tocued")

    if(videoRed.current.played.length === 0){
      console.log("video paused")
      videoRed.current.play()
    }else {
      console.log("Not paused")
    }
  }

  return (
    
      <div className={Styles.root} onClick={forceplayVid} onTouchStart={forceplayVid}>
      <div className={Styles.headerTop}>
      <h1 className={Styles.LogoText}>NG Investment and Management LTD</h1>
      <div className={Styles.headerTopRight}>
      <NavLink className={Styles.menuButton} to='/'>{ turkish ? 'HOME' : "EV"}</NavLink>
      <p className={Styles.menuButton} onClick={scrollTo}>{ turkish ? 'STAMP CALCULATOR' : "DAMGA HESAP"}</p>
      <NavLink className={Styles.menuButton} to='/contact-us'>{ turkish ? 'CONTACT US' : "İLETİŞİM"}</NavLink>  
      <img className={Styles.IconTrans} onClick={() => setturkish(!turkish)} src={IconTrans}/>
      </div>
    </div>

    <Routes>
      <Route path='/' element={
        
        <div className={Styles.root}>
      <Helmet>
            <title>NG Investments</title>
            <meta name="NG Investments" content="NGIML is an independent boutique property consultancy, offering bespoke services in Residential Sales, Letting and Management for foreign investors with a  focus on Turkish residents and expatriates. Our experienced team have over 5 years market knowledge and expertise." />
          </Helmet>
          <div className={Styles.FirstPan}>
         <div style={{position: 'absolute',display: 'flex', justifyContent: 'center',alignItems: 'center',overflow: 'hidden', borderRadius: '12px', height: '100%', width: '100%'}}>
            <iframe  
             sandbox 
             className={Styles.FirstPanImage} 
             src={`https://player.vimeo.com/video/998107383?title=0&muted=1&autoplay=1&controls=0&loop=1&background=1&app_id=122963&dnt=1`} 
             allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
             title="Buildings" 
             data-ready="true"
             loading="lazy" // Defer offscreen iframes
             ></iframe>  
          </div>

            <img src={Logo} style={{zIndex: 99}} className={Styles.LogoMid}></img>
          </div>
          <div className={Styles.bod}>

          
    <div className={Styles.TextPannel}>
    <img style={{maxHeight: '150px'}} className={Styles.ImgGreet} src={ImgGreet}></img>
      <div>
        
    <p className={Styles.textHeader}>{turkish ? "A little about us.. " : "Hakkımızda biraz bilgi.." }</p>
    <p className={Styles.textPara}>
    {
      turkish ? 'NGIML is an independent boutique property consultancy, offering bespoke services in Residential Sales, Letting and Management for foreign investors with a  focus on Turkish residents and expatriates. Our experienced team have over 5 years market knowledge and expertise.'
      :
      "NGIML, Türkiye'de yerleşik ve gurbetçilere odaklanan yabancı yatırımcılar için konut satış, kiralama ve yönetim alanlarında kişiye özel  hizmetler sunan bağımsız bir butik emlak danışmanlığıdır. Deneyimli ekibimiz 5 yılı aşkın pazar bilgisine ve uzmanlığı sahiptir."
    }
      </p>
    <p style={{paddingTop: '5px'}} className={Styles.textPara}>{turkish ? "As an expert in London property consultancy, I provide a personalised, detailed search, negotiation, acquisition and management services to our clients." : "Londra emlak danışmanı uzmanı olarak, müşterilerimiz kişiselleştirilmiş, detaylı araştırma, pazarlık yapma, en kazançlı ve yönetim hizmetleri sağlıyoruz."}</p>

    </div>
    </div>




    <div className={Styles.Pannels}>
      <div className={Styles.box}>
        <div className={Styles.iconBox} style={{backgroundColor: '#53a0ff'}}>
          <img className={Styles.icon} src={IconPeople}></img>
        </div>
        <p className={Styles.boxHeader}>{turkish ? "Property Management" : "Mülkiyet yönetimi" }</p>
        <p className={Styles.boxText}>• {turkish ? "Managing Tenant Check-in/Out process" : "Kiracı giriş ve çıkış sürecini yönetme" }</p>
        <p className={Styles.boxText}>• {turkish ? "Dealing with tenant disputes" : "Kiracı anlaşmazlıkları ile ilgilenmek" }</p>
        <p className={Styles.boxText}>• {turkish ? "Carry out regular property inspections" : "Düzenli mülk denetimleri gerçekleştirme" }</p>
        <p className={Styles.boxText}>• {turkish ? "Arrange repairs" : "Olabilecek mulk tamirat tadilati ayarlama" }</p>
        <p className={Styles.boxText}>• {turkish ? "Being on call 24/7" : "7/24 Ulaşılabilir olma." }</p>
      </div>

      <div className={Styles.box} >
        <div className={Styles.iconBox} style={{backgroundColor: '#53a0ff'}}>
          <img className={Styles.icon} src={IconMarketing}></img>
        </div>
        <p className={Styles.boxHeader}> {turkish ? "Marketing" : "Pazarlama" }</p>
        <p className={Styles.boxText}>• {turkish ? "Provide expected rental yield" : "Beklenen kira getirisini sunma" }</p>
        <p className={Styles.boxText}>• {turkish ? "Work with local letting agents to select the right tenant" : "Doğru kiracıyı seçmek için yerel emlakçilarla iletisime gecip çalışma" }</p>
        <p className={Styles.boxText}>• {turkish ? "Negotiate and agree the rent with the prospective tenant" : "Muhtemel kiracı aday ile kirayı müzakere edip ve anlaşma" }</p>
        <p className={Styles.boxText}>• {turkish ? "Provide expected achievable selling price" : "Beklenen ulaşılabilir satış fiyatını sunma" }</p>
      </div>
      
      <div className={Styles.boxImage} style={{ backgroundImage: `url(${'https://images.unsplash.com/photo-1532444143931-9f60a76242e7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'})`}}>
      </div>
      


    </div>

    

    <div className={Styles.Section2}>
      <img className={Styles.Section2Image} src={VidGif}></img>

      <div className={Styles.Secontion2Right}>
          <p className={Styles.textHeader} style={{inlineSize: '400px'}}>{turkish ? "Making Investment Smooth & Simple" : "Yatırımı basitleştirmek" }</p>
            <div className={Styles.Pannel2}>
              
              <div className={Styles.box2}>
                <img className={Styles.icon2} src={IconTime}></img>
                <p className={Styles.textHeader2}>{turkish ? "24/7" : "7/24" }</p>
                <p className={Styles.text2}>{turkish ? "Were avalible around the clock. " : "24 saat mevcut." }</p>
              </div>
              
              <div className={Styles.box2}>
                <img className={Styles.icon2} src={IconCale}></img>
                <p className={Styles.textHeader2}>{turkish ? "Meetings" : "Toplantılar" }</p>
                <p className={Styles.text2}>{turkish ? "Frequent meetings for progress updates. " : "İlerleme güncellemeleri için sık toplantılar." }</p>
              </div>
          
              <div className={Styles.box2} style={{border: 'none'}}>
                <img className={Styles.icon2} src={IconAna}></img>
                <p className={Styles.textHeader2}> {turkish ? "Networking" : "Ağ oluşturma" }</p>
                <p className={Styles.text2}>{turkish ? "We can connect you to mortgage, legal and tax professionals. " : "Sizi Mortgage, Hukuk ve Vergi uzmanları ile iletişime geçirebiliriz." }</p>
              </div>
            </div>
        </div>
    
    </div>


    <div className={Styles.SectionInstruct}>    
      <div className={Styles.InstuctLeft}>
          <p className={Styles.textHeader}>{turkish ? "Instructed by" : "Agentasi Olduğumuz Yapım Firmalari" }</p>     
          <div className={Styles.logosCont}>
            <img src={ImgLogoBerk} className={Styles.SmLogo}/>
            <img src={ImgLogoReg} className={Styles.SmLogo}/>
          </div>
        </div>
      <img className={Styles.SecInstructImg} src={MetGif}></img>
    </div>




 


   
    <div className={Styles.Pannels}>
      <div className={Styles.boxImage} style={{ backgroundImage: `url(${'https://images.unsplash.com/photo-1534863039390-2ce738c89f58?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80'})`}}>
      </div>
      

      
      <div className={Styles.box} style={{flex: 2}}>
      
        <div className={Styles.iconBox} style={{backgroundColor: '#53a0ff'}}>
          <img className={Styles.icon} src={IconPeople}></img>
        </div>
        <p className={Styles.boxHeader}> {turkish ? "Purchase of Property" : "Mülk Satın Alma" }</p>
        <p className={Styles.boxText}>{turkish ? "If you are planning to buy a property, then we can help to " : "Bir mülk satın almayı planlıyorsunuz, size yardımcı olabiliriz." }</p>
        <p className={Styles.boxText}>•  {turkish ? "Find the right property based on your budget and location" : "Bütçeniz ve istediğiniz konuma göre doğru mülkü bulma." } </p>
        <p className={Styles.boxText}>•  {turkish ? "Negotiate and agree the price" : "Pazarlık yapıp fiyatı kararlastirma" }</p>
        <p className={Styles.boxText}>•  {turkish ? "Recommend and connect with conveyancing solicitor and work with them for a painless purchase" : "Mülkiyet Satis/Alim Avukatı onerme ve islemlerin sorunsuz ilerlemesi icin takipte kalma." }</p>
        <p className={Styles.boxText}>•  {turkish ? "Compute the purchase cost (incl. taxation) under different scenarios" : "Farklı senaryo altında satın alma maliyeti (vergi dahil) hesaplama." }</p>
        <p className={Styles.boxText}>•  {turkish ? "Connect you to mortgage brokers" : "Sizi Mortgage firmaları ile iletişime geçirmeye yardımcı olma." }</p>
        
      

      </div>
    </div>

 



    <div ref={calcRef} className={Styles.StampSection}>
      <div className={Styles.StampCont}>
      <p className={Styles.textHeader}> {turkish ? "Stamp Duty Calculator" : "Damga Vergisi Hesaplayıcı" }</p>
        <Calculator turkish={turkish}/>
        </div>
        <img className={Styles.StampImg} src={ImgSve}></img>
    </div>
    
          </div>
        </div>
      }/>

      <Route path='/contact-us' element={
          <div className={Styles.root}>
            <Helmet>
            <title>Contact Us</title>
            <meta name="Contact Us" content="Feel free to contact us directly by email via nursel@ngiml.com or fill out the form below for any inquiries you may have." />
          </Helmet>
                <div className={Styles.ContactPannel}>
                  <div className={Styles.ContactCont}>
                    <p className={Styles.textHeader}>{turkish ? "Contact us" : "Bize Ulaşın"}</p>
                    {turkish ? 
                    <p className={Styles.textPara}>Feel free to contact us directly via <strong>nursel@ngiml.com</strong> or fill out the form below for any inquiries you may have. Our office is open <strong>Monday to Friday</strong> from <strong>9am to 5pm</strong>.</p>
                    :
                    <p className={Styles.textPara}>Herhangi bir sorunuz için  <strong>nursel@ngiml.com</strong> aracılığıyla doğrudan bizimle iletişime geçmekten veya aşağıdaki formu doldurmaktan çekinmeyin. Ofisimiz <strong>Pazartesiden Cumaya</strong> from <strong>09:00 - 17:00 arası açıktır
                    </strong>.</p>
                  }                    
                  </div>
                
                </div>
                <div ref={calcRef} className={Styles.Section2} style={{height: 'auto',minHeight: '30vh', marginBottom: '3vh', marginTop: '4vh', justifyContent: 'center', alignItems: 'center'}}>
                      <ContactUs turkish={turkish}/>
                </div>



          </div>
      }/>

      <Route path='*' element={
          <div className={Styles.root}>
            <title>Page Not Found!</title>
            <div className={Styles.Section2} style={{flex: '1',justifyContent: 'center', alignItems:'center', height: '55vh', marginBottom: '3vh', flexDirection: 'column-reverse'}}>
              <div className={Styles.Secontion2Right} style={{justifyContent: 'center', alignItems: 'center'}}>
                <p className={Styles.textHeader} style={{margin: '0px'}}>Error 404</p>
                <p className={Styles.textPara} style={{inlineSize: 'auto'}}>Page not found</p>
              </div>
        <img className={Styles.Section2Image} style={{margin: '0px', height: '40vh'}} src={ImgErr}></img>
    </div>
          </div>
      }/>
      
    </Routes>
      
  </div>
  );
}

export default App;
