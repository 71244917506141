
import styles from './scalc.module.css'
import React from 'react'
import { useState } from 'react'
import { flushSync } from 'react-dom'
import { useRef } from 'react'

const Scalc = ({turkish}) => {

    const inputRef = useRef()

    const [S1, setS1] = useState(true)
    const [S2, setS2] = useState(false)
    const [S3, setS3] = useState(false)
    const [S4, setS4] = useState(false)
    const [S5, setS5] = useState(false)
    const [S6, setS6] = useState(false)
    const [S7, setS7] = useState(false)

    const [inputful, setinputful] = useState(false)

    const [firstprop, setfirstprop] = useState(false)
    const [b2l, setb2l] = useState(false)
    const [ukres, setukres] = useState(false)
    const [moving, setmoving] = useState(false)

    const [topay, settopay] = useState(0)
    const [rate, setrate] = useState(0)

    function CheckRemaining(x){
        if(x >= 1){
            console.log('Theres remaning')
            return true
        } else {
            console.log('Nothing remaning')
            return false
        }
    }

    function AmountRemaining(tempamount, overamount, stanper){
        let totalPercent = stanper
        if(ukres === false) totalPercent = totalPercent + 2
        if(b2l === true) totalPercent = totalPercent + 3 
        totalPercent = (totalPercent / 100)
    
        console.log("Percentage", totalPercent)
        console.log('In:', tempamount)
        if(tempamount > overamount){
            
            console.log("Checking Range:", overamount, " Amount Remain:", tempamount - overamount, "To Pay In Range:",(overamount * totalPercent) + overamount, "Taxed:", ((overamount * totalPercent) + overamount) - overamount)
            return [tempamount - overamount, (overamount * totalPercent) + overamount, true] 
        }else {
            console.log("Checking Range:", overamount, " Amount Remain:", 0, "To Pay In Range:",(tempamount * totalPercent) + tempamount, "Taxed:", tempamount * totalPercent)
            return [0, (tempamount * totalPercent) + tempamount, false]  
        }

    }

    function numberWithCommas(x) {
        let y = Number(Math.floor(x))
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const inputChaneg = (x) => {

        console.clear()
        let ar 
        let temp = x
        let totalToPay = 0
   
        if(x.toString().length >= 1){
            setinputful(true)
           
            console.log("Amount: ", x)
            
            if(firstprop && x < 625_000){
                ar = AmountRemaining(temp, 425_000, 0)
                temp = ar[0]
                totalToPay = totalToPay + ar[1]
            } else if(firstprop && x > 625_000) {
                ar = AmountRemaining(temp, 250_000, 0)
                temp = ar[0]
                totalToPay = totalToPay + ar[1]
            } else {
                ar = AmountRemaining(temp, 250_000, 0)
                temp = ar[0]
                totalToPay = totalToPay + ar[1]
            }    
           
            if(ar[2] === true){
                ar = AmountRemaining(temp, 675_000, 5)
                temp = ar[0]
                totalToPay = totalToPay + ar[1]
            }
            
                
                if(ar[2] === true){
                    ar = AmountRemaining(temp, 575_000, 10)
                    temp = ar[0]
                    totalToPay = totalToPay + ar[1]
                    console.log("Total To Pay", totalToPay)
                }
                if(ar[2] === true){
                    ar = AmountRemaining(temp, 100_500_000, 12)
                    temp = ar[0]
                    totalToPay = totalToPay + ar[1]
                }
            

            console.log("Total To Pay", totalToPay)
            console.log("Stamp Duty", totalToPay - x)
            var tp =  numberWithCommas(totalToPay)
            var rat = numberWithCommas(totalToPay - x)
          
            settopay(tp)
            setrate(rat)

        }else {
            setinputful(false)
        }
    }

    function toPayPer(percentage, num){
        let x = (percentage / 100) * num
        x=Math.floor(x)+ Math.floor(num)
        
        return x
    }
    

    const calculate = () => {
        if (inputful === true){
            setS6(false); setS7(true)
            console.log('calculating')
        } 
    }

    const restart = () => {
        inputRef.current.value = ''
        setS6(false)
        setS7(false)
        setS1(true)
        settopay(0)
        setrate(0)
        setmoving(false)
        setfirstprop(false)
        setb2l(false)
        setukres(false)
    }
    
    return(
        <div className={styles.root}>
            
            <div className={S1 ? styles.show : styles.hide}>
                <p className={styles.button} onClick={() => { setS1(false); setS2(true)}}>{turkish ? "Start Now" : "Başla" }</p>
                <p className={styles.subText}>
                    {turkish ? 
                    "Stamp Duty Land Tax is a tax paid to HMRC when you buy houses, flats and other land and buildings over a certain price in the UK. The tax is also paid by buyers from overseas (non-UK residents) at a 2% surcharge when buying property in the UK."
                    :
                    "Damga Vergisi Arazi Vergisi, Birleşik Krallık'ta belirli bir fiyat üzerinden ev, daire ve diğer arazi ve binaları satın aldığınızda HMRC'ye ödenen bir vergidir. Vergi ayrıca denizaşırı ülkelerden (İngiltere dışında ikamet eden) alıcılar tarafından Birleşik Krallık'ta mülk satın alırken %2 ek ücret karşılığında ödenir."
                    }
                    </p>
            </div>

            
            <div className={S2 ? styles.show : styles.hide}>
                <p className={styles.boxText}>{turkish ? "Is the property your first home? " : "Mülk ilk eviniz mi?" }</p>
                <div className={styles.buttons}>
                    <p className={styles.buttonS} onClick={() => { setS2(false); setS4(true); setfirstprop(true)}}>{turkish ? "Yes" : "Evet" }</p>
                    <p className={styles.buttonS} onClick={() => { setS2(false); setS3(true)}}>{turkish ? "No" : "Hayir" }</p>
                </div>
                <p className={styles.back} onClick={() => { setS2(false); setS1(true)}}>{turkish ? "Back" : "Geri" }</p>
            </div>



            <div className={S3 ? styles.show : styles.hide}>
                <p className={styles.boxText}> {turkish ? "Are you moving into a new home? " : "Yeni bir eve mi taşınıyorsunuz?" }</p>
                <div className={styles.buttons}>
                    <p className={styles.buttonS} onClick={() => { setS3(false); setS5(true); setmoving(true)}}>{turkish ? "Yes" : "Evet" }</p>
                    <p className={styles.buttonS} onClick={() => { setS3(false); setS4(true)}}>{turkish ? "No" : "Hayir" }</p>
                </div>
                <p className={styles.back} onClick={() => { setS3(false); setS2(true)}}> {turkish ? "Back" : "Geri" }</p>
            </div>


            <div className={S4 ? styles.show : styles.hide}>
                <p className={styles.boxText}>{turkish ? "Is your property buy to let or a second home?" : "Mülkünüz kiralık mı yoksa ikinci bir ev mi?" }</p>
                <div className={styles.buttons}>
                    <p className={styles.buttonS} onClick={() => { setS4(false); setS5(true); setb2l(true)}}>{turkish ? "Yes" : "Evet" }</p>
                    <p className={styles.buttonS} onClick={() => { setS4(false); setS5(true)}}>{turkish ? "No" : "Hayir" }</p>
                </div>
                <p className={styles.back} onClick={() => { setS4(false); setS2(true)}}> {turkish ? "Back" : "Geri" }</p>
            </div>

            <div className={S5 ? styles.show : styles.hide}>
                <p className={styles.boxText}>{turkish ? "Is the buyer a UK resident?" : "Alıcı Birleşik Krallık'ta mı ikamet ediyor?" }</p>
                <div className={styles.buttons}>
                    <p className={styles.buttonS} onClick={() => { setS5(false); setS6(true); setukres(true)}}>{turkish ? "Yes" : "Evet" }</p>
                    <p className={styles.buttonS} onClick={() => { setS5(false); setS6(true)}}>{turkish ? "No" : "Hayir" }</p>
                </div>
                <p className={styles.back} onClick={() => { setS5(false); setS4(true)}}> {turkish ? "Back" : "Geri" }</p>
            </div>


            <div className={S6 ? styles.show : styles.hide}>
                <p className={styles.boxText}>{turkish ? "What is the price of the property? " : "Mülkün fiyatı nedir?" }</p>
                <div className={styles.buttons}>
                    <p className={styles.pund}>£</p>
                    <input onChange={(x) => inputChaneg(x.target.value)} className={styles.input} ref={inputRef} placeholder='1,200,000' lang="en" type="number"></input>
                </div>
                    <p className={inputful ? styles.buttonS : styles.buttonInvalid } onClick={calculate}>{turkish ? "Calculate" : "Hesapla" }</p>
                <p className={styles.back} onClick={() => { setS6(false); setS5(true)}}> {turkish ? "Back" : "Geri" }</p>
            </div>


            <div className={S7 ? styles.show : styles.hide}>
                <div className={styles.Outcome}>
                    <div className={styles.OutcomeIn}>
                    <p className={styles.boxText}>{turkish ? "To Pay" : "ödenecek miktar" }</p>
                    <p className={styles.bigtext}>£{topay}</p>
                    </div>
                    <div className={styles.OutcomeInR}>
                    <p className={styles.boxText}>{turkish ? "Stamp Duty" : "Pul vergisi" }</p>
                    <p className={styles.bigtext}>£{rate}</p>
                    </div>
                </div>
                <p className={styles.back} onClick={restart}> {turkish ? "Restart" : "Tekrar başlat" }</p>
            </div>

        </div>
    )
}

export default Scalc;