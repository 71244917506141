
import styles from './contact.module.css'
import { useRef } from 'react'
import { useState } from 'react';

import emailjs from '@emailjs/browser';

import ImgContact from './Media/Contact.png'

const ContactUs = ({turkish}) => {


    const [submitted, setsubmitted] = useState(false)


    const inputRef = useRef()
    const formRef = useRef()

const inputChaneg = (x) => {
    console.log(x)
}

const sendEmail = (e) =>{
    
    e.preventDefault();

    emailjs.sendForm('service_e82jqid', 'template_ma3dmpk', formRef.current, 'xzEWMHtBaig6H6SgK')
      .then((result) => {
          console.log(result.text);
          setsubmitted(true)
      }, (error) => {
          console.log(error.text);
      });
}



return(
    
        <form ref={formRef} onSubmit={sendEmail} className={styles.form}>
                <img  className={styles.img} src={ImgContact}></img>
        <div className={submitted ? styles.hide : styles.Inner}>
            <div className={styles.first}>
                <input name='from_name' onChange={(x) => inputChaneg(x.target.value)} className={styles.input} ref={inputRef} autocomplete="name" placeholder={turkish ? "Name" : "İsim"} lang="en" type="text" required/>
                <input name='email' onChange={(x) => inputChaneg(x.target.value)} className={styles.input} ref={inputRef} autocomplete="email" placeholder={turkish ? "Email" : "E-posta"} lang="en" type="email" required/>
                <input name='subject' onChange={(x) => inputChaneg(x.target.value)} className={styles.input} ref={inputRef} placeholder={turkish ? "Subject" : "Konu"} lang="en" type="text"></input>
            </div>
            <div className={styles.first}>
            <input name='message' style={{flex: '2.2'}} onChange={(x) => inputChaneg(x.target.value)} className={styles.input} ref={inputRef} placeholder={turkish ? "Message" : "Mesaj"} lang="en" type="text" required/>
            <input className={styles.button} type="submit" />
            </div>
        </div>
        <p className={submitted ? styles.SubText : styles.hide}> {turkish ? "Message Submitted 😊" : "Mesaj Gönderildi 😊"}</p>
        </form>
    
    )
}

export default ContactUs